/* .recipe-image {
  width: 100%;
  aspect-ratio: 3/2;
  max-height: 500px;
  object-fit: cover;
} */
/* 
.recipe-image-container {
  position: relative;
  text-align: center;
  color: white;
} */

#new-recipe-title-text {
  position: absolute;
  top: 8;
  width: 100%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -0%);
  opacity: 0.6;
}

#new-recipe-camera-icon {
  position: absolute;
  top: 8;
  width: 100%;
  left: 95%;
  top: 80%;
  transform: translate(-50%, -0%);
  opacity: 0.6;
  cursor: pointer;
}

.ingredient-container {
  border-radius: 15px;
}
