.pointer {
  cursor: pointer;
}

/*TODO convert to mui theme */
.secondary-color {
  background-color: #f8efde;
}

.primary-color {
  background-color: #74897b;
}
